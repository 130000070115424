import React from "react";
import { Context } from "./index";

export interface TrackingManager {
  ready(version: string): void;
  click(targetURL: string, label: string, clickID: string, text: string): void;
  impression(selectedContent: string, text: string): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: "benefit communication teaser",
    currentURL: `${host}${pathname}`,
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = React.useContext(Context);
  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        logger && logger.debug("Sending tracking event: ", payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        logger && logger.debug("Sending tracking event: ", payload);

        const componentPayload = createComponentPayload(version);
        logger && logger.debug("Sending component info", componentPayload);

        // TODO update tracking service with componentPayload as second argument
        // when the service has been updated
        trackingService.track(payload);
      }
    }

    return {
      ready(version) {
        trackWithComponent(version, "feature_app_ready", "feature app ready", {
          label: "",
          targetURL: "",
          clickID: "",
          elementName: "",
          value: "",
          pos: "",
        });
      },

      click(targetURL, label, clickID, text) {
        const checkLinkTarget = (url: string) => {
          const tempLink = document.createElement("a");
          tempLink.href = url;

          if (tempLink.hostname === window.location.hostname) {
            return "internal_link";
          }

          return "exit_link";
        };

        track(
          checkLinkTarget(targetURL),
          `benefit communication teaser - ${clickID} - click`,
          {
            label,
            elementName: "button",
            targetURL,
            clickID,
            value: text,
            pos: "",
          }
        );
      },

      impression(individualId, text) {
        track(
          "impression",
          `benefit communication teaser - ${individualId} - impression`,
          {
            label: "",
            targetURL: "",
            clickID: individualId,
            elementName: "",
            value: text,
            pos: "",
          }
        );
      },
    };
  }, [trackingService]);
}
