import { ReactNodeArray } from "react";
import { ConsumptionsAndEmissions } from "@volkswagen-onehub/audi-vue-formatter-service";

import {
  MyAudiBenefitCommunicationTeaserProps,
  WltpProps,
  ImageProps as ComponentImageProps,
} from "../component/CompontentTypes";
import {
  ButtonUrls,
  ContentVariant,
  I18nTexts,
  ImageProps,
} from "../EditorContentTypes";
import {
  getImageUrls,
  renderTextWithFootnotesReferences,
  cleanupEmissionConsumptionMarkup,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

const mapImages = (image: ImageProps): ComponentImageProps => {
  return {
    xs: getImageUrls(image.xs.assetValue)[0],
    m: getImageUrls(image.m.assetValue)[0],
    xxl: getImageUrls(image.xxl.assetValue)[0],
    altText: image.altText,
  };
};

export const mapToTeaserProps = (
  { individualId, image, text, legalData }: ContentVariant,
  buttonUrls: ButtonUrls,
  i18nTexts: I18nTexts,
  myAudiText: string,
  consumptionsAndEmissions: ConsumptionsAndEmissions[]
): MyAudiBenefitCommunicationTeaserProps => {
  let additionalText: ReactNodeArray | undefined;
  if (legalData.additionalText) {
    additionalText = renderTextWithFootnotesReferences(
      legalData.additionalText
    );
  }

  const wltpData: WltpProps[] = [];
  consumptionsAndEmissions.forEach(
    ({ formattedConsumption, formattedEmission }) => {
      if (formattedConsumption && formattedEmission) {
        wltpData.push({
          formattedConsumption: renderTextWithFootnotesReferences(
            cleanupEmissionConsumptionMarkup(formattedConsumption)
          ),
          formattedEmission: renderTextWithFootnotesReferences(
            cleanupEmissionConsumptionMarkup(formattedEmission)
          ),
        });
      }
    }
  );

  return {
    individualId,
    image: mapImages(image),
    text: renderTextWithFootnotesReferences(text),
    myAudiText,
    registerButton: {
      href: buttonUrls.registerButtonUrl,
      text: i18nTexts.registerButtonText,
    },
    learnMoreButton: {
      href: buttonUrls.learnMoreButtonUrl,
      text: i18nTexts.learnMoreButtonText,
    },
    legalData: {
      additionalText,
      wltpData,
    },
  };
};
