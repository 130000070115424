import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  AudiPlatformProvider,
  audiDarkTheme,
  ThemeProvider,
} from "@audi/audi-ui-react";
import {
  getConsumptionsAndEmissions,
  transformToTrackingText,
  useContent,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

import { AsyncStateHolder, State } from ".";
import { MyAudiBenefitCommunicationTeaser } from "./component";
import { MyAudiBenefitCommunicationTeaserProps } from "./component/CompontentTypes";
import { Context } from "./context";
import { I18nContextProvider } from "./i18n/i18nContext";
import { useTrackingManager } from "./context/useTrackingManager";
import { useInViewObserver } from "./context/useInViewObserver";
import { randomContentVariant } from "./utils/randomContent";
import { mapToTeaserProps } from "./utils/mapToTeaserProps";
import * as pkg from "../package.json";

import { ContentVariant, Content } from "./EditorContentTypes";
import { useI18n } from "./i18n";
import {
  usePreviewVariant,
  PreviewVariantMatchingMessage,
} from "./context/usePreviewVariant";

const MyAudiTeaserFeatureApp: React.FunctionComponent = () => {
  const previewVariant = usePreviewVariant();
  const [previewVariantError, setPreviewVariantError] = useState(false);
  const { vueFormatterService, localeService, logger } = useContext(Context);
  const inView = useInViewObserver();

  const trackingManager = useTrackingManager();

  const myAudiText = "myAudi";

  const i18nTexts = {
    registerButtonText: useI18n("registerButtonText"),
    learnMoreButtonText: useI18n("learnMoreButtonText"),
  };
  const content = useContent<Content>();

  const [selectedContentVariant, setSelectedContentVariant] =
    useState<ContentVariant>();

  const [footnoteRegisteredContent, setFootnoteRegisteredContent] =
    useState<MyAudiBenefitCommunicationTeaserProps>();

  useEffect(() => {
    trackingManager.ready(pkg.version);
  }, []);

  useEffect(() => {
    if (content) {
      const selectContentByIndividualId = (individualId: string) =>
        [content.defaultContent, ...content.variants].find((variant) => {
          return variant.individualId === individualId;
        });
      if (previewVariant) {
        // preview variant is configured
        const updatedSelectedContentVariant =
          selectContentByIndividualId(previewVariant);
        setPreviewVariantError(!updatedSelectedContentVariant);
        setSelectedContentVariant(updatedSelectedContentVariant);
      } else if (selectedContentVariant) {
        const updatedSelectedContentVariant = selectContentByIndividualId(
          selectedContentVariant.individualId
        );
        if (updatedSelectedContentVariant) {
          setSelectedContentVariant(updatedSelectedContentVariant);
        } else {
          // tracking id that was selected before got changed or removed
          setSelectedContentVariant(randomContentVariant(content));
        }
      } else {
        // no content variant was shown ever
        setSelectedContentVariant(randomContentVariant(content));
      }
    }
  }, [content]);

  useEffect(() => {
    let isMounted = true;

    if (content && selectedContentVariant) {
      getConsumptionsAndEmissions(
        selectedContentVariant.legalData.wltpKeys,
        vueFormatterService,
        localeService,
        logger
      ).then((consumptionsAndEmissions) => {
        if (isMounted) {
          const mappingProps = mapToTeaserProps(
            selectedContentVariant,
            content.buttons,
            i18nTexts,
            myAudiText,
            consumptionsAndEmissions
          );

          setFootnoteRegisteredContent(mappingProps);
        }
      });
    } else {
      setFootnoteRegisteredContent(undefined);
    }

    return () => {
      isMounted = false;
    };
  }, [selectedContentVariant]);

  useEffect(() => {
    if (inView && selectedContentVariant) {
      trackingManager.impression(
        selectedContentVariant.individualId,
        transformToTrackingText(`${selectedContentVariant.text} ${myAudiText}`)
      );
    }
  }, [inView, selectedContentVariant]);

  const linkTracking = useCallback(
    (href: string, text: string, clickId: string): void => {
      trackingManager.click(
        href,
        text,
        clickId,
        transformToTrackingText(`${selectedContentVariant?.text} ${myAudiText}`)
      );
    },
    [selectedContentVariant]
  );

  return (
    <AudiPlatformProvider>
      <ThemeProvider theme={audiDarkTheme}>
        {previewVariant && (
          <PreviewVariantMatchingMessage error={previewVariantError} />
        )}
        {footnoteRegisteredContent && (
          <MyAudiBenefitCommunicationTeaser
            {...footnoteRegisteredContent}
            linkTracking={linkTracking}
          />
        )}
      </ThemeProvider>
    </AudiPlatformProvider>
  );
};

MyAudiTeaserFeatureApp.displayName = "MyAudiTeaserFeatureApp";

interface Props {
  readonly asyncStateHolder: AsyncStateHolder;
}

const AsyncFeatureApp: React.FunctionComponent<Props> = ({
  asyncStateHolder,
}: Props) => {
  const { authServiceProvider, featureAppGroup, logger } = useContext(Context);

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  // when asyncStateHolder is an object it represents the serialized state coming from the server ready to be used as the
  // initial state
  const [state, setState] = useState<State | undefined>(
    typeof asyncStateHolder === "object" ? asyncStateHolder : undefined
  );

  React.useEffect(() => {
    let mounted = true;
    const authService = authServiceProvider.register(featureAppGroup);

    authService.isAuthenticated().then((authenticated) => {
      if (mounted && !authenticated) {
        setIsAuthenticated(authenticated);
      }
    });

    // when asyncStateHolder is a function it means the state could not be properly serialized by the server and it is
    // not available on the client. In that case this effect will try to fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === "function") {
      logger?.info("SSR did not serialize any state");
      asyncStateHolder().then((state) => {
        if (mounted) {
          setState(state);
        }
      });
    } else {
      logger?.info("SSR serialized state: ", asyncStateHolder);
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (!state || isAuthenticated) {
    return null;
  }

  return (
    <I18nContextProvider i18nMessages={state.i18nMessages}>
      <MyAudiTeaserFeatureApp />
    </I18nContextProvider>
  );
};

export default AsyncFeatureApp;
