import React from "react";
import styled from "styled-components";
import { ButtonGroup, Button, Text } from "@audi/audi-ui-react";
import { addImwidthParameter } from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

import { ImageWidth, toSrcSet } from "./utils/url-utils";

import {
  MyAudiBenefitCommunicationTeaserProps,
  WltpProps,
} from "./CompontentTypes";

const GRID_COLUMNS = 25;
const GRID_WIDTH_PERCENTAGE = 100 / GRID_COLUMNS;
type GridImageColumns = 10 | 11 | 12;
const toPercentage = (columns: GridImageColumns) =>
  `${GRID_WIDTH_PERCENTAGE * columns}%`;

const Container = styled.div`
  background: ${(props) => props.theme.colors.ui.primaryInverse};

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;
const ImageBox = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    flex: 0 0 ${toPercentage(10)};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    flex: 0 0 ${toPercentage(11)};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    flex: 0 0 ${toPercentage(12)};
  }
`;
const StyledImg = styled.img`
  width: 100%;
  height: 370px;
  object-fit: cover;
  display: block;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    height: auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 590px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: 768px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    height: 948px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    height: 1080px;
  }
`;

const StyledBlockSpan = styled.span`
  display: block;
`;

StyledBlockSpan.displayName = "StyledBlockSpan";

const TextBox = styled.div`
  padding: var(${(props) => props.theme.responsive.spacing.xxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
`;

const WltpSpan = styled.span`
  display: block;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: inline-block;

    &:first-child {
      margin-right: var(${(props) => props.theme.responsive.spacing.s});
    }
  }
`;

WltpSpan.displayName = "WltpSpan";

const WltpValuesComponent: React.FC<WltpProps> = ({
  formattedConsumption,
  formattedEmission,
}: WltpProps) => {
  return (
    <StyledBlockSpan>
      <WltpSpan data-testid="consumption">{formattedConsumption}</WltpSpan>
      <WltpSpan data-testid="emission">{formattedEmission}</WltpSpan>
    </StyledBlockSpan>
  );
};

export const MyAudiBenefitCommunicationTeaser: React.FunctionComponent<
  MyAudiBenefitCommunicationTeaserProps
> = ({
  individualId,
  image,
  text,
  myAudiText,
  registerButton,
  learnMoreButton,
  linkTracking,
  legalData,
}: MyAudiBenefitCommunicationTeaserProps) => (
  <Container>
    <ImageBox>
      <picture>
        <source
          media="(min-width:1920px)"
          srcSet={toSrcSet(image.xxl, ImageWidth.XXL)}
          data-testid="imageXXL"
        />
        <source
          media="(min-width:768px)"
          srcSet={toSrcSet(image.m, ImageWidth.M)}
          data-testid="imageM"
        />
        <StyledImg
          srcSet={toSrcSet(image.xs, ImageWidth.XS)}
          src={addImwidthParameter(image.xs, ImageWidth.XS)}
          alt={image.altText}
          data-testid="image"
        />
      </picture>
    </ImageBox>
    <TextBox>
      <Text as="h3" variant="order2">
        {text}
        <br />
        <Text as="strong" weight="bold" variant="order2">
          {myAudiText}
        </Text>
      </Text>
      <ButtonGroup variant="block-buttons" spaceStackStart="xl">
        <Button
          variant="secondary"
          href={registerButton.href}
          data-testid="register"
          onClick={() =>
            linkTracking &&
            linkTracking(registerButton.href, registerButton.text, individualId)
          }
        >
          {registerButton.text}
        </Button>
        <Button
          variant="secondary"
          href={learnMoreButton.href}
          data-testid="learn-more"
          onClick={() =>
            linkTracking &&
            linkTracking(
              learnMoreButton.href,
              learnMoreButton.text,
              individualId
            )
          }
        >
          {learnMoreButton.text}
        </Button>
      </ButtonGroup>
      {(legalData.wltpData.length > 0 || legalData.additionalText) && (
        <Text variant="copy2" spaceStackStart="xxl" data-testid="legal">
          {legalData.wltpData &&
            legalData.wltpData.map((data, index) => (
              <WltpValuesComponent {...data} key={index} />
            ))}
          {legalData.additionalText && (
            <WltpSpan data-testid="additional-legal-text">
              {legalData.additionalText}
            </WltpSpan>
          )}
        </Text>
      )}
    </TextBox>
  </Container>
);
