/* eslint-disable react/display-name */
import React from "react";
import { ReactFeatureApp } from "@feature-hub/react";
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from "@feature-hub/core";
import { ContentServiceV1 } from "@volkswagen-onehub/audi-content-service";
import { I18NServiceV1 } from "@volkswagen-onehub/audi-i18n-service";
import { AsyncSsrManagerV1 } from "@feature-hub/async-ssr-manager";
import { SerializedStateManagerV1 } from "@feature-hub/serialized-state-manager";
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from "@volkswagen-onehub/audi-footnote-reference-service";
import { VueFormatterServiceInterfaceV1 } from "@volkswagen-onehub/audi-vue-formatter-service";
import { GfaLocaleServiceV1 } from "@volkswagen-onehub/gfa-locale-service";
import { GfaAuthServiceProviderV1 } from "@volkswagen-onehub/gfa-auth-service";
import {
  ContentContextProvider,
  FootnoteContextProvider,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";
import { ITrackingServiceV1 } from "@volkswagen-onehub/audi-tracking-service";

import { PreviewVariantContextProvider } from "./context/usePreviewVariant";
import { ContextProvider } from "./context";
import { I18nMessages, fetchI18nMessages } from "./i18n";
import AsyncFeatureApp from "./feature-app";

export interface Dependencies extends FeatureServices {
  readonly "s2:async-ssr-manager"?: AsyncSsrManagerV1;
  readonly "s2:logger": Logger;
  readonly "s2:serialized-state-manager"?: SerializedStateManagerV1;
  readonly "audi-content-service": ContentServiceV1;
  readonly "audi-footnote-reference-service": AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly "audi-tracking-service"?: ITrackingServiceV1;
  readonly "dbad:audi-i18n-service": I18NServiceV1;
  readonly "dbad:audi-vue-formatter-service": VueFormatterServiceInterfaceV1;
  readonly "gfa:locale-service": GfaLocaleServiceV1;
  readonly "gfa:auth-service": GfaAuthServiceProviderV1;
}

export interface Config {
  readonly featureAppGroup?: string;
  readonly previewVariant?: string;
}

export interface State {
  readonly i18nMessages: I18nMessages;
}

/**
 * It holds a function to retrieve the State needed by the feature app when it is rendered on the server
 * It holds the State object created from the serialized state on the client
 */
export type AsyncStateHolder = (() => Promise<State>) | State | undefined;

const MyAudiTeaserFeatureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  Config
> = {
  dependencies: {
    externals: {
      "@audi/audi-ui-react": "^1.4.0",
      "@feature-hub/react": "^2.7.0",
      react: "^16.13.1",
      "react-dom": "^16.13.1",
      "styled-components": "~5.1.1",
    },
    featureServices: {
      "audi-content-service": "^1.0.0",
      "dbad:audi-i18n-service": "^1.0.0",
      "gfa:locale-service": "^1.0.0",
    },
  },
  optionalDependencies: {
    featureServices: {
      "s2:logger": "^1.0.0",
      "audi-tracking-service": "^1.0.0",
      "s2:async-ssr-manager": "^1.0.0",
      "s2:serialized-state-manager": "^1.0.0",
      "audi-footnote-reference-service": "3.0.0",
      "dbad:audi-vue-formatter-service": "^1.0.0",
      "gfa:auth-service": "^1.0.0",
    },
  },

  create: ({ config, featureServices }) => {
    const featureAppGroup = config?.featureAppGroup || "myaudi";
    const {
      "audi-content-service": contentService,
      "audi-tracking-service": trackingService,
      "gfa:locale-service": localeService,
      "s2:logger": logger,
      "audi-footnote-reference-service": footnoteReferenceServiceScopeManager,
      "dbad:audi-i18n-service": i18nService,
      "s2:async-ssr-manager": asyncSsrManager,
      "s2:serialized-state-manager": serializedStateManager,
    } = featureServices;

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName =
        "audi-myaudi-benefit-communication-teaser";
    }

    async function createInitialState(): Promise<State> {
      const i18nMessages = await fetchI18nMessages(localeService, i18nService);
      return { i18nMessages };
    }

    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          asyncStateHolder = await createInitialState();
          serializedStateManager?.register(() =>
            JSON.stringify(asyncStateHolder)
          );
        })()
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();
      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState) as State;
        /** TODO
         * Remove once SSR on author preview mode was fixed by BaD. THIS IS JUST A TEMPORARY WORKAROUND AND NOT A PERMANENT SOLUTION
         */
        if (
          !asyncStateHolder?.i18nMessages?.learnMoreButtonText ||
          !asyncStateHolder?.i18nMessages?.registerButtonText
        ) {
          logger?.warn(
            `Serialized state was found, but didn't contain the expected i18n texts!. Reason: SSR doesn't resolve i18n correctly on author in preview mode. As a workaround the state will be reinitialized.`
          );
          asyncStateHolder = () => createInitialState();
        }
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`
        );
        asyncStateHolder = () => createInitialState();
      }
    }

    return {
      render: () => (
        <ContentContextProvider contentService={contentService}>
          <FootnoteContextProvider
            footnoteReferenceService={footnoteReferenceService}
          >
            <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
              <ContextProvider
                featureServices={featureServices}
                featureAppGroup={featureAppGroup}
              >
                <PreviewVariantContextProvider
                  previewVariant={config?.previewVariant}
                >
                  <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                </PreviewVariantContextProvider>
              </ContextProvider>
            </div>
          </FootnoteContextProvider>
        </ContentContextProvider>
      ),
    };
  },
};

export default MyAudiTeaserFeatureAppDefinition;
